import React from 'react';
import {Skeleton, TableCell} from '@mui/material'
import {fonts} from '../../util/Theme'

/**
 * TableCell formatted to use font size configured in Theme.js
 * @component 
 * @category Stylized Components 
 */
function SubmittedCell({children, loading=false}) {
    return (
        <TableCell align='center' sx={{ fontSize: fonts.tableSize }}>
            {loading ? <Skeleton/>: children}
        </TableCell>
    );
}

export default SubmittedCell;