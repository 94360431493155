import { Button, CircularProgress, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import AlternateRow from "../../../components/AlternateRow";
import SubmittedCell from "../../../components/Submitted/SubmittedCell";
import moment from "moment";
import { removeOfferDraft } from "../../../util/offerLettersHelp";
import { downloadDocusign } from "../../../util/docusignHelper";
/**
 * component used to view sent offer letters
 * @component 
 * @category Offer Letters 
 */

const defaultPagination = {
    total_items:0,
    limit:25,
    current_page:0
}

function ViewOffers({offers, updateOffers, onError, unvoidableStatuses, pagination=defaultPagination, loading, onPageinate}){
    const [loadingIndices, setLoadingIndices] = useState([])
    const [searchString, setSearchString] = useState([])

    const handleChangePage = (event, newPage) => {
        onPageinate(newPage+1,pagination.limit)
    };
    
    const handleChangeRowsPerPage = (event, newValue) => {
        const newLimit = Number(event.target.value)
        //ensure page isn't too big
        onPageinate(Math.min(pagination.current_page, Math.floor((pagination.total_items+1) / newLimit)), newLimit)
    };

    const updateLoadingIndices = (id, method="append") => {
        let newIndices = [...loadingIndices]
        if(method === 'append'){
            newIndices.push(id)
        }else if(method === 'delete' && loadingIndices.includes(id)){
            newIndices.splice(newIndices.indexOf(id))
        }
        setLoadingIndices(newIndices)
    }

    const voidOfferLetter = (id) => {
        updateLoadingIndices(id)
        
        removeOfferDraft(id, (resp)=>{
            if(resp){
                if(updateOffers){
                    updateOffers()
                }
            }else{
                if(onError){
                    onError()
                }
            }
        },false)
    }

    // const resendOfferLetter = (id) => {
    //     updateLoadingIndices(id)
    //     resendEnvelope(id, (resp)=>{    
    //         if(props.onError && !resp){
    //             props.onError()        
    //         }
    //         updateLoadingIndices(id,'delete')
    //     })
    // }

    const downloadOfferLetter = (id, env_id) => {
        updateLoadingIndices(id)
        downloadDocusign(env_id, (blob)=>{
            if(!blob){
                onError({errorTitle: "Uh Oh!", errorContent:"Something went wrong when trying to download the document, please try again"})
            }else{
                var url  = window.URL.createObjectURL(blob);
                window.open(url);
            }
            updateLoadingIndices(id,'delete')
        })
    }

    // const handleSearch  = (search) =>{
    //     console.log('search',search)
    //     setSearchString(search)

    //     //filter all offers s.t field contains searchstring
    //     let newOffers = offers.filter((offer) => {
    //         console.log('test',offer.date_submitted, typeof(offer.date_submitted))
    //         for(let key of Object.keys(offer)){
    //             if(prsearchKeys.includes(key) && String(offer[key]).toUpperCase().includes(search.toUpperCase())){
    //                 return true
    //             } 
    //         }
    //         return false
    //     })

    //     setSearchOffers(newOffers)
    // }

    return(
        <Grid container sx={{padding:'2%'}}>
            {/* <TextField label='Search' value={searchString} onChange={(e)=>handleSearch(e.target.value)}/> */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <SubmittedCell>Candidate Name</SubmittedCell>
                            <SubmittedCell>Candidate Email</SubmittedCell>
                            <SubmittedCell>Candidate Region</SubmittedCell>
                            <SubmittedCell>Date Sent (YYYY-MM-DD)</SubmittedCell>
                            <SubmittedCell>Signing Authority</SubmittedCell>
                            <SubmittedCell>Status</SubmittedCell>
                            <SubmittedCell/>
                            <SubmittedCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            offers && offers.length > 0 ? (
                                <>
                                {offers.map((offer)=>(
                                    <AlternateRow>
                                        <SubmittedCell loading={loading}>
                                            {offer.name}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            {offer.email_address}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            {offer.region}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            {moment.utc(offer.date_submitted).format("YYYY-MM-DD").toString()}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            {offer.authority_name}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            {offer.status}
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            <Button disabled={(unvoidableStatuses && unvoidableStatuses.includes(offer.status)) || offer.voided
                                            || loadingIndices.includes(offer.id)} 
                                                onClick={(e)=>{e.preventDefault(); voidOfferLetter(offer.id)}}>
                                                    {loadingIndices.includes(offer.id) ? "Please Wait" : "Void Offer"}
                                            </Button>
                                        </SubmittedCell>
                                        <SubmittedCell loading={loading}>
                                            <Button disabled={offer.status !== 'completed' || loadingIndices.includes(offer.id)} 
                                                onClick={(e)=>{e.preventDefault(); downloadOfferLetter(offer.id,offer.envelope_id)}}>
                                                    {loadingIndices.includes(offer.id) ? "Please Wait" : "Download Offer"}
                                            </Button>
                                        </SubmittedCell>
                                    </AlternateRow>
                                ))}
                                </>
                            ):<></>
                        }
                    </TableBody>
                </Table>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={pagination.total_items}
                rowsPerPage={pagination.limit || 25}
                page={pagination.current_page-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Grid>
    )   
}

ViewOffers.propTypes ={
    /**
     * list of offer letter objects following db schema
     */
    offers : PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * function called when an error occures
     */
    onError: PropTypes.func,
    /**
     * function called to update offers
     */
    updateOffers : PropTypes.func,
    /**
     * statuses that aren't allowed to voided
     */
    unvoidableStatuses : PropTypes.arrayOf(PropTypes.string),
    /**
     * keys in the offer letter object that can be searched by
     */
    searchKeys: PropTypes.arrayOf(PropTypes.string)
}

ViewOffers.defaultProps = {
    searchKeys : ['name', 'email_address', 'region', 'date_submitted', 'authority_name', 'status'],
    unvoidableStatuses: ['voided', 'deleted', 'completed']
}

export default ViewOffers