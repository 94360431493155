import React from 'react';
import { Grid, Typography } from '@mui/material'
/**
 * Standard footer used in rendering of web pages
 * @component
 * @category Headers and Footers
 */
function Footer(props) {
    return (
        <Grid item container xs={12} sx={{display:'flex', justifyContent:'center',alignItems:'center', bgcolor:'primary.main', height:'100%'}}>
            <Typography align='center' variant='subtitle1' color='text.secondary'>Copyright SEQ Technology &copy; {String(new Date().getUTCFullYear())} | All Rights Reserved</Typography>
        </Grid>
    );
}

export default Footer;