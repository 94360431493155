import { Grid,Tab, Tabs } from "@mui/material";
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from "@mui/lab";
import ManageOffers from './ManageOffers'
import ManageContracts from "./ManageContracts";
import { getOfferInfo, getOfferLetters, getContracts } from "../../../util/offerLettersHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";

/**
 * Page responsible for handling Offer Letters
 * @component
 * @category Pages
 * @subcategory Admin
 */
function Envelopes(props){
    const [currForm, setCurrForm] = useState(0)
    const [loadingDetails, setLoadingDetails] = useState(true)
    const [signingAuth, setSigningAuth] = useState([])
    const [contacts,setContacts]=useState([])
    const [states,setStates]=useState([])
    const [venues,setVenues]=useState([])
    const [clients, setClients] = useState([])
    const [errorObj, setErrorObj]  = useState({})
    const [recruiters, setRecruiters] = useState([])
    const [accountManagers, setAccountManagers] = useState([])

    const updateInfo = (abortCont=undefined) => {
        getOfferInfo(abortCont, (json)=>{
            if(json){
                setSigningAuth(json.authorities)
                setContacts(json.contacts)
                setStates(json.states)
                setVenues(json.venues)
                setClients(json.clients)
                setRecruiters(json.recruiters)
                setAccountManagers(json.account_managers)
            }else{
                handleError()
            }
            setLoadingDetails(false)
            
        })
    }

    const handleError = (errorObject=undefined) => {
        try{
            setErrorObj({errorTitle: errorObject.errorTitle, errorContent:errorObject.errorContent})
        }catch{
            setErrorObj({errorTitile: undefined, errorContent:undefined})
        }
    }

    useEffect(()=>{
        let abortCont = new AbortController()
        //console.log('use effect triggered')
        updateInfo(abortCont)
        return () => abortCont.abort()
    }, [])


    return(
        <TabContext value={currForm}>
            <LoadingPopUp open={loadingDetails}/>
            <Tabs
            value={currForm}
            onChange={(e,value) => {e.preventDefault(); setCurrForm(value)}}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            >
                <Tab label="Offer Letters"/>
                <Tab label="Contracts"/>
            </Tabs>
            <SwipeableViews
            index={currForm}
            onChangeIndex={setCurrForm}>
                <TabPanel value={currForm} index={0}>
                    <ManageOffers {...props} authorities={signingAuth} contacts={contacts}
                    states={states} venues={venues} clients={clients} recruiters={recruiters} accountManagers={accountManagers}/>
                </TabPanel>
                <TabPanel value={currForm} index={1}>
                    <ManageContracts {...props} authorities={signingAuth} clients={clients} 
                    states={states} venues={venues}/>
                </TabPanel>
            </SwipeableViews>
        </TabContext>    
    )
}

Envelopes.propTypes={
    /**
     * profile of currently signed in user
     */
    profile: PropTypes.object.isRequired
}

export default Envelopes