import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography, MenuItem, Autocomplete } from '@mui/material'
import { DatePicker } from '@mui/lab';
import PropTypes from 'prop-types'
import GridBreak from '../../../components/GridBreak'
import { createOfferDraft, removeOfferDraft, submitDraft } from "../../../util/offerLettersHelp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import IFramePopUp from '../../../components/Pop_ups/IFramePopUp';
import { useBeforeunload } from 'react-beforeunload';
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import DragFiles from "../../../components/FileManagement/DragFiles";
import { fileTo64, fileTo64Arr, handleFormNumbers } from "../../../util/generalHelp";
import { useBackListener } from "../../../util/Hooks";
import { previewDocusign } from "../../../util/docusignHelper";
import TextFieldWithCheckBox from "../../../components/TextFieldWithCheckBox";

const fileTypes = ['docx', 'pdf']

/**
 * component used to create and send Offer Letters
 * @component 
 * @category Offer Letters
 */

function OfferForm({optionalFields, states, venues, clients, authorities, contacts, 
    recruiters, accountManagers, onComplete, onError}) {
    const defaultObj = {
        document_args: {
            "job_title": "",
            // sick_days: 0,
            // vacation_days: 0,
            salary: 0,
            start_date: null,
            due_date: null,
            // 'pay_freq': 'semi-monthly',
            'pay_type': "SALARY",
            address: undefined
            // bonus: false,
            // benefits: false,
            // overtime: false,
            // overtime_rate: 0,
            // benefit_narrative: "Specifically, your offer of employment includes the following salary and benefits:",
            // overtime_narrative: "Overtime work above 80 hours in a two week period at the rate of"
        },
        "name": "",
        "first_name":"",
        "last_name":"",
        "email_address": "",
        "authority_id": "",
        contact_id: "",
        // venue_id: "",
        // state_id: "",
        "region": "USA",
        recruiter: undefined,
        account_manager: undefined,
        "return_url": window.location.origin.toString(),
        "type": "SALARY"
    }

    const [offerObj, setOfferObj] = useState({ ...defaultObj })
    const [previewUrl, setPreviewUrl] = useState()
    const [previewClicked, setPreviewClicked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileArr, setFileArr] = useState([])
    const [uploadStatus, setUploadStatus] = useState('error')
    const [envelopeId, setEnvelopeId] = useState('')

    //ensuring client is added to stuff as needed
    useEffect(() => {
        if (offerObj.type === 'SALARY' && !offerObj.client_id) {
            updateOffer('client_id', undefined)
        }
        if (offerObj.type !== 'SALARY' && !offerObj.client_id) {
            updateOffer('client_id', undefined, true)
        }
    }, [offerObj.type])

    useEffect(() => {
        if (offerObj.first_name || offerObj.last_name) {
            updateOffer('name', offerObj.first_name + " " + offerObj.last_name)
        }
    }, [offerObj.first_name,offerObj.last_name])
    
    useBackListener(({ location }) => {
        if (offerObj.id) {
            console.log('test')
            removeOfferDraft(offerObj.id, (resp) => { }, true)
        }
    })


    useBeforeunload((event) => {
        if (offerObj.id) {
            console.log('test')
            //    console.log('test')
            //    console.log('return value',event.return_value)
            removeOfferDraft(offerObj.id, (resp) => { }, true)

        }
    });

    useEffect(() => {
        console.log('fileArr', fileArr)
        if (fileArr && fileArr.length > 0) {
            setUploadStatus('success')
        } else {
            setUploadStatus('pending')
        }
    }, [fileArr])

    useEffect(()=>{
        console.log('test', recruiters, accountManagers)
    }, [recruiters, accountManagers])

    const [textFieldValue, setTextFieldValue] = useState('');


    const [checkboxes, setCheckboxes] = useState([
        { label: 'Access to the SEQ Technology Healthcare Plan (Medical, Dental and Vision)', checked: false },
        { label: 'Access to the SEQ Technology 401K Plan', checked: false },
        { label: '10 days of annualized vacation leave', checked: false },
        { label: ' 10 days of annualized holiday leave', checked: false },
        { label: '5 days of annualized sick leave', checked: false },
    ]);



    const checkJson = (json) => {
        for (const key of Object.keys(json)) {
            if (json[key] === null || json[key] === undefined || json[key] === '') {
                console.log('key', key)
                if (!optionalFields.includes(key))
                    return false
            }

            if (json[key].constructor == Object) {
                if (!checkJson(json[key])) {
                    return false
                }
                continue
            }
        }
        return true
    }

    const updateOffer = (key, value, del = false) => {
        let newOffer = { ...offerObj }
        if (!del) {
            newOffer[key] = value
            setOfferObj(newOffer)
        } else {
            delete newOffer[key]
            setOfferObj(newOffer)
        }

    }

    const updateDocument = (key, value) => {
        let newDoc = { ...offerObj }
        newDoc['document_args'][key] = value
        setOfferObj(newDoc)
    }

    const handleDraft = async () => {

        if(!offerObj.document_args.benefit_narrative){
        updateDocument('benefit_narrative', offerObj.document_args.benefit_narrative + '\n' + textFieldValue)
        }
        console.log('offerObj', offerObj)

        if (previewUrl && offerObj.id) {
            setLoading(true)
            submitDraft(offerObj.id, envelopeId, (resp) => {
                if (resp) {
                    //console.log('submission successful')
                    setOfferObj(defaultObj)
                    setFileArr([])
                    setPreviewUrl()
                    if (onComplete) {
                        onComplete()
                    }
                } else {
                    onError({})
                }
                setLoading(false)
            })
        } else {
            setLoading(true)
            setPreviewUrl()
            if (checkJson(offerObj)) {
                let request_obj = { ...offerObj }
                //console.log('testing testing 123', fileArr)
                request_obj.document_b64s = await handleFile(fileArr)
                createOfferDraft(request_obj, (resp) => {
                    if (resp) {
                        //console.log('draft resp',resp)
                        updateOffer('id', resp.id)
                        setPreviewUrl(resp.redirect_url)
                        setUploadStatus('disabled')
                        setEnvelopeId(resp.envelope_id)
                    } else {
                        onError({})
                    }
                    setLoading(false)
                })
            } else {
                onError({ errorTitle: 'Missing Form Fields', errorContent: "Please fill out all available form fields" })
                setLoading(false)
            }

        }

    }

    const handleClear = () => {
        if (offerObj.id) {
            setLoading(true)
            removeOfferDraft(offerObj.id, (resp) => {
                if (resp) {
                    let newOffer = { ...offerObj }
                    delete newOffer['id']
                    setOfferObj(newOffer)
                    setPreviewUrl()
                    setUploadStatus('pending')
                } else {
                    onError({})
                }
                setLoading(false)
            })
        }
    }

    const handleFile = async (fileArr) => {
        return await fileTo64Arr(fileArr)
    }

    const handleNumberForm = (e, key, document = false, decimals = 0) => {
        let modfiedNum = handleFormNumbers(e.target.value, decimals)
        if (modfiedNum !== undefined) {
            if (document) {
                updateDocument(key, modfiedNum)
            } else {
                updateOffer(key, modfiedNum)
            }
        }
    }

    const closePreview = () => {
        setPreviewClicked(false)
        previewDocusign(envelopeId, (json) => {
            if (json) {
                setPreviewUrl(json.redirect_url)
            }
        })
    }

    return (
        <Grid sx={{ flexDirect: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '2%', height: '100%' }}>
            <LoadingPopUp open={loading} />
            <IFramePopUp open={previewClicked} url={previewUrl} onClose={closePreview} maxRedirect={2} />
            <Typography align='center' variant='h4'>Manage Offers</Typography>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%', padding: '1%' }}>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="First Name" onChange={(e) => { e.preventDefault(); updateOffer('first_name', e.target.value) }}
                        value={offerObj.first_name} fullWidth required />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Last Name" onChange={(e) => { e.preventDefault(); updateOffer('last_name', e.target.value) }}
                        value={offerObj.last_name} fullWidth required />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Email Address" onChange={(e) => { e.preventDefault(); updateOffer('email_address', e.target.value) }}
                        value={offerObj.email_address} fullWidth required />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Address" onChange={(e) => { e.preventDefault(); updateDocument('address', e.target.value) }}
                        value={offerObj.document_args.address} fullWidth required />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Region" required onChange={(e) => { e.preventDefault(); updateOffer('region', e.target.value) }}
                        value={offerObj.region} fullWidth select>
                        <MenuItem key={1} value="CAN">
                            CAN
                        </MenuItem>
                        <MenuItem key={2} value="USA">
                            USA
                        </MenuItem>
                    </TextField>
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Job Type" onChange={(e) => { e.preventDefault(); updateOffer('type', e.target.value) }}
                        value={offerObj.type} fullWidth select required>
                        <MenuItem key={1} value="CORPORATE">
                            Corporate
                        </MenuItem>
                        <MenuItem key={2} value="SALARY">
                            Salary
                        </MenuItem>
                    </TextField>
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Job Title" onChange={(e) => { e.preventDefault(); updateDocument('job_title', e.target.value) }}
                        value={offerObj.document_args.job_title} fullWidth required />
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Pay Type" onChange={(e) => { e.preventDefault(); updateDocument('pay_type', e.target.value) }}
                        value={offerObj.document_args.pay_type} fullWidth select required>
                        <MenuItem key={1} value="HOURLY">
                            Hourly
                        </MenuItem>
                        <MenuItem key={2} value="SALARY">
                            Salary
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    {offerObj.document_args.pay_type === 'SALARY' ?
                        <TextField disabled={Boolean(previewUrl)} label="Salary ($)" SelectProps='0.01' onChange={(e) => {
                            e.preventDefault();
                            handleNumberForm(e, 'salary', true, 2)
                        }}
                            value={offerObj.document_args.salary} fullWidth required /> :
                        <TextField disabled={Boolean(previewUrl)} label="Salary ($/hr)" SelectProps='0.01' onChange={(e) => {
                            e.preventDefault();
                            handleNumberForm(e, 'salary', true, 2)
                        }}
                            value={offerObj.document_args.salary} fullWidth required />
                    }
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Pay Frequency" onChange={(e) => { e.preventDefault(); updateDocument('pay_freq', e.target.value) }}
                        value={offerObj.document_args.pay_freq} fullWidth select required>
                        <MenuItem key={1} value='weekly'>
                            Weekly
                        </MenuItem>
                        <MenuItem key={2} value="semi-monthly">
                            Semi-Monthly
                        </MenuItem>
                        <MenuItem key={3} value="monthly">
                            Monthly
                        </MenuItem>
                    </TextField>
                </Grid> */}
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={previewUrl || offerObj.region === 'USA'} label="Sick Days" onChange={(e) => {
                        e.preventDefault();
                        handleNumberForm(e, 'sick_days', true)
                    }}
                        value={offerObj.document_args.sick_days} fullWidth required={offerObj.region !== 'USA'} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={previewUrl || offerObj.region === 'USA'} label="Vaction Days" onChange={(e) => {
                        e.preventDefault();
                        handleNumberForm(e, 'vacation_days', true)
                    }}
                        value={offerObj.document_args.vacation_days} fullWidth required={offerObj.region !== 'USA'} />
                </Grid> */}
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField label="Performance Bonus" SelectProps='0.01' onChange={(e) => {e.preventDefault(); updateOffer('bonus',Math.trunc(Math.max(e.target.value,0) * 100)/100)}}
                        value={offerObj.bonus} fullWidth  disabled={offerObj.type !== 'SALARY'}/>
                </Grid> */}

                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Overtime Allowed" onChange={(e) => { e.preventDefault(); updateDocument('overtime', e.target.value) }}
                        value={offerObj.document_args.overtime} fullWidth select required>
                        <MenuItem key={1} value={false}>
                            No
                        </MenuItem>
                        <MenuItem key={2} value={true}>
                            Yes
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField label="Overtime Explanation" SelectProps='0.01' onChange={(e) => { e.preventDefault(); updateDocument('overtime_narrative', e.target.value) }}
                        value={offerObj.document_args.overtime_narrative} fullWidth multiline
                        disabled={true} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={!offerObj.document_args.overtime || Boolean(previewUrl)}
                        label="Overtime Rate ($)" SelectProps='0.01'
                        onChange={(e) => {
                            e.preventDefault();
                            handleNumberForm(e, 'overtime_rate', true, 2)
                        }}

                        value={offerObj.document_args.overtime_rate} fullWidth required />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Bonus Included" onChange={(e) => { e.preventDefault(); updateDocument('bonus', e.target.value) }}
                        value={offerObj.document_args.bonus} fullWidth select required>
                        <MenuItem key={1} value={false}>
                            No
                        </MenuItem>
                        <MenuItem key={2} value={true}>
                            Yes
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} label="Benefits Included" onChange={(e) => { e.preventDefault(); updateDocument('benefits', e.target.value) }}
                        value={offerObj.document_args.benefits} fullWidth select required >
                        <MenuItem key={1} value={false}>
                            No
                        </MenuItem>
                        <MenuItem key={2} value={true}>
                            Yes
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWithCheckBox
                        checkboxes={checkboxes}
                        setCheckboxes={setCheckboxes}
                        textFieldValue={textFieldValue}
                        setTextFieldValue={setTextFieldValue}
                        onChange={(e) => { e.preventDefault(); updateDocument('benefit_narrative', e.target.value) }}
                        label="Benefit Explanation"
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        multiline
                        value={offerObj.document_args.benefit_narrative}
                        displayCheckBox={offerObj.document_args.benefits || previewUrl}
                    />
                </Grid> */}
                <Grid item md={3} sm={6} xs={12}>
                <Autocomplete
                    disabled={Boolean(previewUrl)}
                    disablePortal
                    options={recruiters}
                    value={offerObj.recruiter}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`  || ''} // Render name field
                    onChange={(e, newValue) => updateOffer('recruiter', newValue?.id || null)} // Pass id to updateDocument
                    renderInput={(params) => <TextField required {...params} label="Recruiter" />}
                />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Autocomplete
                    disabled={Boolean(previewUrl)}
                    disablePortal
                    options={accountManagers}
                    value={offerObj.account_manager}
                    getOptionLabel={(option) => `${option.first_name} ${option.last_name} (${option.email})`  || ''} // Render name field
                    onChange={(e, newValue) => updateOffer('account_manager', newValue?.id || null)} // Pass id to updateDocument
                    renderInput={(params) => <TextField required {...params} label="Account Manager" />}
                />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField label="Client" required={true} disabled={previewUrl} onChange={(e) => { e.preventDefault(); updateOffer('client_id', e.target.value) }}
                        value={offerObj.client_id} fullWidth select>
                        {clients.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.short_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <DatePicker disabled={Boolean(previewUrl)} label="Start Date" value={offerObj.document_args.start_date} onChange={(value) => updateDocument('start_date', value.format('YYYY-MM-DD').toString())}
                        renderInput={(params) => <TextField required fullWidth {...params} />}
                        minDate={offerObj.document_args.due_date ? moment.max(moment(), moment(offerObj.document_args.due_date)) : moment()} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <DatePicker disabled={Boolean(previewUrl)} label="Due Date" value={offerObj.document_args.due_date} required onChange={(value) => updateDocument('due_date', value.format('YYYY-MM-DD').toString())}
                        renderInput={(params) => <TextField required fullWidth {...params} />} minDate={moment.utc()}
                        maxDate={moment(offerObj.document_args.start_date)} />
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={previewUrl || offerObj.region === 'USA'} label="Probation Length" onChange={(e) => {
                        e.preventDefault();
                        handleNumberForm(e, 'period_length', true)
                    }}
                        value={offerObj.document_args.period_length} fullWidth />
                </Grid> */}
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={previewUrl || offerObj.region === 'USA'} label="Probation Interval" onChange={(e) => { e.preventDefault(); updateDocument('period_interval', e.target.value) }}
                        value={offerObj.document_args.period_interval} fullWidth select>
                        <MenuItem key={1} value="months">
                            Month(s)
                        </MenuItem>
                        <MenuItem key={2} value="weeks">
                            Week(s)
                        </MenuItem>
                        <MenuItem key={3} value='days'>
                            Day(s)
                        </MenuItem>
                    </TextField>
                </Grid> */}

                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} required label="Signing Authority" onChange={(e) => { e.preventDefault(); updateOffer('authority_id', e.target.value) }}
                        value={offerObj.authority_id} fullWidth select>
                        {authorities.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}({auth.email_address})
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} required label="Contacts" onChange={(e) => { e.preventDefault(); updateOffer('contact_id', e.target.value) }}
                        value={offerObj.contact_id} fullWidth select>
                        {contacts.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}({auth.email_address})
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl)} required label="State"
                        onChange={(e) => { e.preventDefault(); updateOffer('state_id', e.target.value) }}
                        value={offerObj.state_id} fullWidth select>
                        {states.map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                {/* <Grid item md={3} sm={6} xs={12}>
                    <TextField disabled={Boolean(previewUrl) || !offerObj.state_id} required label="Venue"
                        onChange={(e) => { e.preventDefault(); updateOffer('venue_id', e.target.value) }}
                        value={offerObj.venue_id} fullWidth select>
                        {venues.filter(v => v.type === offerObj.state_id).map((auth) => (
                            <MenuItem key={auth.id} value={auth.id}>
                                {auth.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <GridBreak />
                <Grid item container xs={12} sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <DragFiles types={fileTypes} multiple={true} disabled={Boolean(previewUrl)} onUpdate={setFileArr}
                        maxSize={15}
                        onTypeError={(error) => { setUploadStatus('error'); onError({ errorTitle: 'File Type Error', errorContent: error }) }}
                        onSizeError={(error) => { setUploadStatus('error'); onError({ errorTitle: 'File Size Error', errorContent: error }) }}
                        uploadStatus={uploadStatus} />
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth disabled={!previewUrl || !offerObj.id} onClick={(e) => { e.preventDefault(); handleClear() }}>Cancel</Button>
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth disabled={!previewUrl || !offerObj.id} onClick={(e) => { e.preventDefault(); setPreviewClicked(true) }}>Preview Document</Button>
                </Grid>
                <Grid item xs={4} container sx={{ justifyContnet: 'center', alignItems: 'center' }}>
                    <Button fullWidth onClick={(e) => { e.preventDefault(); handleDraft() }}>{offerObj.id && previewUrl ? "Send" : "Create Draft"}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

OfferForm.propTypes = {
    /**
     * list of valid signing authorities, following db schema
     */
    authorities: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * list of available clients, follwoing db schema
     */
    clients: PropTypes.arrayOf(PropTypes.object),
    /**
     * function to be called when sending out the offer letter is sucsessful
     */
    onComplete: PropTypes.func,
    /**
     * function to be called when an error occures
     */
    onError: PropTypes.func,
    /**
     * optional fields for the form
     */
    optionalFields: PropTypes.arrayOf(PropTypes.string)
}

OfferForm.defaultProps = {
    onError: () => { },
    optionalFields: ['period_interval', 'period_length', 'benefit_narrative', 'overtime_narrative']
}

export default OfferForm