import React, {useState, useEffect} from "react";
import { Grid } from "@mui/material";
import PropTypes from 'prop-types'
import ContractForm from "./ContractForm";
import ErrorPopUp from "../../../components/Pop_ups/ErrorPopUp";
import LoadingPopUp from "../../../components/Pop_ups/LoadingPopUp";
import { getContracts, getOfferInfo } from "../../../util/offerLettersHelp";
import {isObjectEmpty} from '../../../util/generalHelp'
import ViewOffers from "./ViewOffers";


function ManageContracts(props){

    const unvoidable = ['voided', 'deleted', 'completed']

    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorObj, setErrorObj]  = useState({})
    const [contracts, setContracts] = useState([])
    const [pagination, setPagination] = useState({})
    const [loadingContracts, setLoadingContracts] = useState(false)

    
    const handleError = (errorObject=undefined) => {
        try{
            setErrorObj({errorTitle: errorObject.errorTitle, errorContent:errorObject.errorContent})
        }catch{
            setErrorObj({errorTitile: undefined, errorContent:undefined})
        }
    }

    const retrieveContracts = (page=1, limit=25, abortCont=undefined) => {
        console.log('call', page, limit)
        setLoadingContracts(true)
        getContracts(page, limit, abortCont, (json) => {
            if(json){
                setContracts(json.envelopes)
                setPagination(json)
                setHasLoaded(true)
            }else{
                handleError()
            }
            setLoadingContracts(false)
        })
        
    }

    useEffect(()=>{
        
           
        let abortCont = new AbortController()
        //console.log('use effect triggered')
        if(!hasLoaded){
            retrieveContracts(1, 25, abortCont)
        }
        return () => abortCont.abort()
    }, [])

    const updateContracts = () => {
        // let allContracts = [...contracts]
        // const conIndex = allContracts.findIndex(offer => offer.id === id)
        // let newCon = {...allContracts[conIndex]}

        // newCon[key] = value

        // allContracts[conIndex] = newCon
        // setContracts(allContracts)
        retrieveContracts()
        
    }

    return(
        <Grid container sx={{justifyContent:'center',alignItems:'center'}}>
        <ErrorPopUp errorContent={errorObj.errorContent} errorTitle={errorObj.errorTitle} open={!isObjectEmpty(errorObj)}
            onClose={(e) => {e.preventDefault(); setErrorObj({})}}/>
        {/* <LoadingPopUp open={!hasLoaded}/> */}
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'30%'}}>
            <ContractForm authorities={props.authorities} clients={props.clients}
            states={props.states} venues={props.venues}
            onComplete={retrieveContracts} onError={handleError}/>
        </Grid>
        <Grid container sx={{justifyContent:'center',alignItems:'center', height:'65%', width:'100%'}}>
            <ViewOffers offers={contracts} onError={handleError} updateOffers={updateContracts} unvoidableStatuses={unvoidable}
            loading={loadingContracts} pagination={pagination} onPageinate={retrieveContracts}/>
        </Grid>
    </Grid>
    )
}

export default ManageContracts